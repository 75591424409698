var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c(
                    "b-row",
                    { attrs: { "align-h": "center", "no-gutters": "" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "auto" } },
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.totalPlaybackFiles,
                              "per-page": _vm.itemsPerPage,
                            },
                            model: {
                              value: _vm.page,
                              callback: function ($$v) {
                                _vm.page = $$v
                              },
                              expression: "page",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("template", { slot: "header" }, [
        _c("div", { staticClass: "float-left" }, [
          _vm._v(" " + _vm._s(_vm.title) + " "),
        ]),
        _c(
          "div",
          { staticClass: "float-right" },
          [
            _c(
              "b-btn",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.bottom",
                    value: "Select All",
                    expression: "'Select All'",
                    modifiers: { hover: true, bottom: true },
                  },
                ],
                staticClass: "mr-2",
                attrs: { size: "sm", variant: "secondary" },
                on: {
                  click: function ($event) {
                    return _vm.$refs.fileTable.selectAllRows()
                  },
                },
              },
              [_c("b-icon-grid3x3-gap-fill")],
              1
            ),
            _c(
              "b-btn",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.bottom",
                    value: "Select None",
                    expression: "'Select None'",
                    modifiers: { hover: true, bottom: true },
                  },
                ],
                staticClass: "mr-4",
                attrs: { size: "sm", variant: "secondary" },
                on: {
                  click: function ($event) {
                    return _vm.$refs.fileTable.clearSelected()
                  },
                },
              },
              [_c("b-icon-grid3x3-gap")],
              1
            ),
            _c(
              "b-btn",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.bottom",
                    value: "Download Selected",
                    expression: "'Download Selected'",
                    modifiers: { hover: true, bottom: true },
                  },
                ],
                staticClass: "mr-2",
                attrs: { size: "sm", disabled: this.selected.length === 0 },
                on: {
                  click: function ($event) {
                    return _vm.downloadSelected()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: "download" } })],
              1
            ),
            _c(
              "b-btn",
              {
                directives: [
                  {
                    name: "b-popover",
                    rawName: "v-b-popover.hover.bottom",
                    value: "Delete Selected",
                    expression: "'Delete Selected'",
                    modifiers: { hover: true, bottom: true },
                  },
                ],
                attrs: {
                  size: "sm",
                  variant: "danger",
                  disabled: this.selected.length === 0,
                },
                on: {
                  click: function ($event) {
                    return _vm.deleteSelected()
                  },
                },
              },
              [_c("fa-icon", { attrs: { icon: "trash" } })],
              1
            ),
          ],
          1
        ),
      ]),
      _c("b-table", {
        ref: "fileTable",
        attrs: {
          items: _vm.files,
          filter: this.typeFilter,
          "filter-function": _vm.filterFiles,
          fields: _vm.fields,
          currentPage: _vm.page,
          perPage: _vm.itemsPerPage,
          "sort-icon-left": "",
          responsive: "",
          selectable: "",
        },
        on: { "row-selected": this.rowSelected },
        scopedSlots: _vm._u([
          {
            key: "cell(filename)",
            fn: function (data) {
              return [
                _c("span", [_vm._v(_vm._s(data.item.filename))]),
                data.item.filename === _vm.logger.currentConfig
                  ? _c(
                      "b-badge",
                      { staticClass: "ml-2", attrs: { variant: "success" } },
                      [_vm._v(" Loaded ")]
                    )
                  : _vm._e(),
                data.item.promptLoad
                  ? _c(
                      "b-badge",
                      { staticClass: "ml-2", attrs: { variant: "warning" } },
                      [_vm._v(" Loaded for next session ")]
                    )
                  : _vm._e(),
              ]
            },
          },
          {
            key: "cell(location)",
            fn: function (data) {
              return [
                data.item.onDisk
                  ? _c("fa-icon", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.top",
                          value: _vm.diskIconTooltipText(data.item.onDisk),
                          expression: "diskIconTooltipText(data.item.onDisk)",
                          modifiers: { hover: true, top: true },
                        },
                      ],
                      class: {
                        "m-1": true,
                        available: data.item.onDisk,
                        unavailable: !data.item.onDisk,
                      },
                      attrs: { icon: "database", size: "lg" },
                    })
                  : _vm._e(),
                _c("fa-icon", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.top",
                      value: _vm.loggerIconTooltipText(
                        data.item.onLogger,
                        data.item.promptDownload
                      ),
                      expression:
                        "loggerIconTooltipText(data.item.onLogger, data.item.promptDownload)",
                      modifiers: { hover: true, top: true },
                    },
                  ],
                  class: {
                    "m-1": true,
                    available: data.item.onLogger,
                    unavailable: !data.item.onLogger,
                    pending: data.item.promptDownload,
                    "hide-icon": !data.item.promptDownload,
                  },
                  attrs: { icon: "arrow-alt-circle-right", size: "lg" },
                }),
                _c("fa-icon", {
                  directives: [
                    {
                      name: "b-tooltip",
                      rawName: "v-b-tooltip.hover.top",
                      value: _vm.loggerIconTooltipText(
                        data.item.onLogger,
                        data.item.promptDownload
                      ),
                      expression:
                        "loggerIconTooltipText(data.item.onLogger, data.item.promptDownload)",
                      modifiers: { hover: true, top: true },
                    },
                  ],
                  class: {
                    "m-1": true,
                    available: data.item.onLogger,
                    unavailable: !data.item.onLogger,
                  },
                  attrs: { icon: "microchip", size: "lg" },
                }),
              ]
            },
          },
          {
            key: "cell(id)",
            fn: function (data) {
              return [
                _c(
                  "b-btn-group",
                  [
                    _vm.enableActivateButton(data.item)
                      ? _c(
                          "b-dropdown",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value: "Activate for Next Session",
                                expression: "'Activate for Next Session'",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: { split: "" },
                            on: {
                              click: function ($event) {
                                return _vm.activateConfig(data.item.filename)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("fa-icon", {
                                        attrs: { icon: "sign-in-alt" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(data.item)
                                  },
                                },
                              },
                              [
                                data.item.onDisk
                                  ? _c(
                                      "div",
                                      [
                                        _c("fa-icon", {
                                          attrs: { icon: "download" },
                                        }),
                                        _vm._v(" Download "),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c("fa-icon", {
                                          attrs: { icon: "cloud-download-alt" },
                                        }),
                                        _vm._v(" Fetch from Logger "),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: {
                                  disabled:
                                    !data.item.onDisk || !_vm.hasWriteAccess,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFile(
                                      data.item.filename,
                                      "pmgateway"
                                    )
                                  },
                                },
                              },
                              [
                                _c("fa-icon", { attrs: { icon: "trash" } }),
                                _vm._v(" Delete from PMGateway "),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.enableSendToLoggerButton(data.item)
                      ? _c(
                          "b-dropdown",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value: "Send to Logger",
                                expression: "'Send to Logger'",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: { split: "" },
                            on: {
                              click: function ($event) {
                                return _vm.sendToLogger(data.item.filename)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("fa-icon", {
                                        attrs: { icon: "cloud-download-alt" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(data.item)
                                  },
                                },
                              },
                              [
                                data.item.onDisk
                                  ? _c(
                                      "div",
                                      [
                                        _c("fa-icon", {
                                          attrs: { icon: "download" },
                                        }),
                                        _vm._v(" Download "),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c("fa-icon", {
                                          attrs: { icon: "cloud-upload-alt" },
                                        }),
                                        _vm._v(" Fetch from Logger "),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: {
                                  disabled:
                                    !data.item.onDisk || !_vm.hasWriteAccess,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFile(
                                      data.item.filename,
                                      "pmgateway"
                                    )
                                  },
                                },
                              },
                              [
                                _c("fa-icon", { attrs: { icon: "trash" } }),
                                _vm._v(" Delete from PMGateway "),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm.enableAlreadySendingToLoggerButton(data.item)
                      ? _c(
                          "b-dropdown",
                          {
                            directives: [
                              {
                                name: "b-popover",
                                rawName: "v-b-popover.hover",
                                value: "Pending download",
                                expression: "'Pending download'",
                                modifiers: { hover: true },
                              },
                            ],
                            attrs: { split: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      _c("fa-icon", {
                                        attrs: { icon: "cogs" },
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(data.item)
                                  },
                                },
                              },
                              [
                                data.item.onDisk
                                  ? _c(
                                      "div",
                                      [
                                        _c("fa-icon", {
                                          attrs: { icon: "download" },
                                        }),
                                        _vm._v(" Download "),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c("fa-icon", {
                                          attrs: { icon: "cloud-upload-alt" },
                                        }),
                                        _vm._v(" Fetch from Logger "),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: {
                                  disabled:
                                    !data.item.onDisk || !_vm.hasWriteAccess,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFile(
                                      data.item.filename,
                                      "pmgateway"
                                    )
                                  },
                                },
                              },
                              [
                                _c("fa-icon", { attrs: { icon: "trash" } }),
                                _vm._v(" Delete from PMGateway "),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "b-dropdown",
                          {
                            attrs: { split: "" },
                            on: {
                              click: function ($event) {
                                return _vm.downloadFile(data.item)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "button-content",
                                  fn: function () {
                                    return [
                                      data.item.onDisk
                                        ? _c("fa-icon", {
                                            attrs: { icon: "download" },
                                          })
                                        : _c("fa-icon", {
                                            attrs: { icon: "cloud-upload-alt" },
                                          }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "b-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadFile(data.item)
                                  },
                                },
                              },
                              [
                                data.item.onDisk
                                  ? _c(
                                      "div",
                                      [
                                        _c("fa-icon", {
                                          attrs: { icon: "download" },
                                        }),
                                        _vm._v(" Download "),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      [
                                        _c("fa-icon", {
                                          attrs: { icon: "cloud-upload-alt" },
                                        }),
                                        _vm._v(" Fetch from Logger "),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                            _c(
                              "b-dropdown-item",
                              {
                                attrs: {
                                  disabled:
                                    !data.item.onDisk || !_vm.hasWriteAccess,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFile(
                                      data.item.filename,
                                      "pmgateway"
                                    )
                                  },
                                },
                              },
                              [
                                _c("fa-icon", { attrs: { icon: "trash" } }),
                                _vm._v(" Delete from PMGateway "),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c(
        "b-toast",
        {
          attrs: {
            title: "Download in Progress",
            variant: "secondary",
            visible: this.download.showToast,
            toaster: "b-toaster-bottom-right",
            "no-auto-hide": "",
            "no-close-button": "",
            "is-status": "",
          },
        },
        [
          _c("b-spinner", { staticClass: "mr-2", attrs: { small: "" } }),
          _c("span", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(this.download.label)),
          ]),
          this.download.showProgress
            ? _c("span", [_vm._v("(" + _vm._s(this.download.progress) + "%)")])
            : _vm._e(),
          _c(
            "b-btn",
            {
              staticClass: "ml-2",
              attrs: {
                variant: "outline-danger",
                size: "sm",
                disabled: !this.download.showProgress,
              },
              on: {
                click: function ($event) {
                  return _vm.cancelDownload()
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }