var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading
        ? _c(
            "b-container",
            [
              _c(
                "b-alert",
                { attrs: { variant: "warning", show: _vm.showAdminWarning } },
                [
                  _c("fa-icon", { attrs: { icon: "exclamation-triangle" } }),
                  _vm._v(
                    " Take extra care. You are using admin privileges to access a logger that you would otherwise not have access to "
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "8", cols: "12" } },
                    [
                      _c(
                        "b-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "b-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "8",
                                order: "2",
                                "order-sm": "1",
                              },
                            },
                            [
                              _c("logger-status-icon", {
                                attrs: {
                                  status: _vm.logger.status,
                                  lastupdate: _vm.logger.last_seen,
                                  size: "2x",
                                  nolabel: "",
                                },
                              }),
                              !_vm.name.editing
                                ? _c(
                                    "h2",
                                    { staticStyle: { display: "inline" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            this.logger.name ||
                                              "SN: " + this.serial
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "b-link",
                                        {
                                          directives: [
                                            {
                                              name: "b-popover",
                                              rawName: "v-b-popover.hover",
                                              value: "Edit Name",
                                              expression: "'Edit Name'",
                                              modifiers: { hover: true },
                                            },
                                          ],
                                          staticClass: "h5 ml-1",
                                          on: {
                                            click: function ($event) {
                                              _vm.name.editing = true
                                            },
                                          },
                                        },
                                        [
                                          _c("fa-icon", {
                                            attrs: { icon: "pencil-alt" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "b-input-group",
                                    [
                                      _c("b-input", {
                                        on: {
                                          keyup: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return _vm.setName.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value: _vm.name.newName,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.name, "newName", $$v)
                                          },
                                          expression: "name.newName",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              attrs: {
                                                variant: "danger",
                                                disabled:
                                                  !this.logger.writeAccess,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.name.editing = false
                                                },
                                              },
                                            },
                                            [
                                              _c("fa-icon", {
                                                attrs: { icon: "ban" },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-btn",
                                            {
                                              attrs: {
                                                variant: "success",
                                                disabled:
                                                  !this.logger.writeAccess,
                                              },
                                              on: { click: _vm.setName },
                                            },
                                            [
                                              _c("fa-icon", {
                                                attrs: { icon: "check" },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              attrs: {
                                cols: "12",
                                sm: "4",
                                order: "1",
                                "order-sm": "2",
                              },
                            },
                            [
                              _c(
                                "b-button-group",
                                { staticClass: "float-right" },
                                [
                                  _c(
                                    "b-dropdown",
                                    {
                                      attrs: {
                                        right: "",
                                        text: "Actions",
                                        disabled: !this.logger.writeAccess,
                                      },
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.executeCommand(
                                                "start",
                                                []
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Start Recording ")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.executeCommand(
                                                "stop",
                                                []
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Stop Recording ")]
                                      ),
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.executeCommand(
                                                "restart",
                                                []
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Restart Recording ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "success" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.refresh()
                                        },
                                      },
                                    },
                                    [
                                      _c("fa-icon", {
                                        attrs: {
                                          icon: "sync",
                                          spin: _vm.refreshing,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      directives: [
                                        {
                                          name: "b-modal",
                                          rawName:
                                            "v-b-modal.logger-config-modal",
                                          modifiers: {
                                            "logger-config-modal": true,
                                          },
                                        },
                                      ],
                                      attrs: {
                                        variant: "gray",
                                        disabled: !this.logger.writeAccess,
                                      },
                                    },
                                    [_c("fa-icon", { attrs: { icon: "cog" } })],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      !_vm.description.editing
                        ? _c(
                            "h4",
                            { staticClass: "text-muted ml-1" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    this.logger.description ||
                                      "(no description set)"
                                  ) +
                                  " "
                              ),
                              _c(
                                "b-link",
                                {
                                  directives: [
                                    {
                                      name: "b-popover",
                                      rawName: "v-b-popover.hover",
                                      value: "Edit Description",
                                      expression: "'Edit Description'",
                                      modifiers: { hover: true },
                                    },
                                  ],
                                  staticClass: "h5 ml-1",
                                  on: {
                                    click: function ($event) {
                                      _vm.description.editing = true
                                    },
                                  },
                                },
                                [
                                  _c("fa-icon", {
                                    attrs: { icon: "pencil-alt" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "b-input-group",
                            [
                              _c("b-input", {
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.setDescription.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.description.newDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.description, "newDesc", $$v)
                                  },
                                  expression: "description.newDesc",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { variant: "success" },
                                      on: { click: _vm.setDescription },
                                    },
                                    [_vm._v("Set")]
                                  ),
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { variant: "danger" },
                                      on: {
                                        click: function ($event) {
                                          _vm.description.editing = false
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                      _c(
                        "span",
                        {
                          class: _vm.lastUpdatedTextStyle,
                          staticStyle: { "white-space": "nowrap" },
                        },
                        [
                          _vm._v(
                            " Last Updated: " +
                              _vm._s(
                                (_vm.logger.last_seen &&
                                  _vm.$d(
                                    new Date(_vm.logger.last_seen),
                                    "long"
                                  )) ||
                                  "(never)"
                              ) +
                              " "
                          ),
                        ]
                      ),
                      _c(
                        "b-container",
                        { staticClass: "mt-1 mb-2", attrs: { fluid: "" } },
                        [
                          _c(
                            "b-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _vm._l(
                                _vm.meterReadings.slice(0, 8),
                                function (value) {
                                  return _c(
                                    "b-col",
                                    {
                                      key: value.channel,
                                      attrs: { cols: "6", md: "4", lg: "3" },
                                    },
                                    [
                                      _c(
                                        "b-card",
                                        {
                                          staticClass: "meter-card",
                                          attrs: {
                                            header:
                                              value.channel +
                                              ": " +
                                              value.description,
                                            "body-class": "meter-body",
                                            "header-class": "meter-header",
                                            "header-bg-variant": "ui-yellow",
                                            "header-text-variant": "dark",
                                          },
                                        },
                                        [
                                          _c("b-card-title", [
                                            _vm._v(_vm._s(value.value)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                              _c(
                                "b-collapse",
                                {
                                  attrs: { id: "logger-meter-collapse" },
                                  model: {
                                    value: _vm.meterReadingsVisible,
                                    callback: function ($$v) {
                                      _vm.meterReadingsVisible = $$v
                                    },
                                    expression: "meterReadingsVisible",
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "no-gutters": "" } },
                                    _vm._l(
                                      _vm.meterReadings.slice(8),
                                      function (value) {
                                        return _c(
                                          "b-col",
                                          {
                                            key: value.channel,
                                            attrs: {
                                              cols: "6",
                                              md: "4",
                                              lg: "3",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                staticClass: "meter-card",
                                                attrs: {
                                                  header:
                                                    value.channel +
                                                    ": " +
                                                    value.description,
                                                  "body-class": "meter-body",
                                                  "header-class":
                                                    "meter-header",
                                                  "header-bg-variant":
                                                    "ui-yellow",
                                                  "header-text-variant": "dark",
                                                },
                                              },
                                              [
                                                _c("b-card-title", [
                                                  _vm._v(_vm._s(value.value)),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                              this.meterReadings.length > 8
                                ? _c(
                                    "b-btn",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName:
                                            "v-b-toggle.logger-meter-collapse",
                                          modifiers: {
                                            "logger-meter-collapse": true,
                                          },
                                        },
                                      ],
                                      staticClass: "mt-1",
                                      attrs: {
                                        variant: "outline-primary",
                                        block: "",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            this.meterReadingsVisible
                                              ? "Collapse"
                                              : "Expand"
                                          ) +
                                          " Meter Readings "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                          _vm.dataTooOld(_vm.logger.last_seen)
                            ? _c("span", { class: _vm.lastUpdatedTextStyle }, [
                                _vm._v(
                                  " Warning: Data is more than an hour old so may not be reliable "
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "4", cols: "12" } },
                    [
                      _c(
                        "b-row",
                        { attrs: { "no-gutters": "" } },
                        [_c("b-col", { attrs: { cols: "12" } })],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-2", attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "b-col",
                            {
                              staticStyle: { "min-height": "300px" },
                              attrs: { cols: "12" },
                            },
                            [
                              !!_vm.logger.location ||
                              !!_vm.logger.manualLocation
                                ? _c(
                                    "div",
                                    { staticStyle: { height: "90%" } },
                                    [
                                      _c(
                                        "l-map",
                                        {
                                          staticStyle: { "z-index": "0" },
                                          attrs: {
                                            zoom: _vm.zoom,
                                            center: _vm.displayedLocation,
                                          },
                                        },
                                        [
                                          _c("l-tile-layer", {
                                            attrs: { url: _vm.url },
                                          }),
                                          _c(
                                            "l-marker",
                                            {
                                              attrs: {
                                                visible: true,
                                                "lat-lng":
                                                  _vm.displayedLocation,
                                              },
                                            },
                                            [
                                              _c("l-tooltip", {
                                                attrs: {
                                                  content:
                                                    _vm.logger.name ||
                                                    _vm.logger.serial,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("l-circle", {
                                            attrs: {
                                              "lat-lng": _vm.displayedLocation,
                                              radius:
                                                _vm.displayedLocationAccuracy,
                                              visible:
                                                _vm.displayedLocationAccuracy >
                                                0,
                                              color: "blue",
                                              weight: 1,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm.logger.location &&
                                      _vm.logger.location.source == "GPS"
                                        ? _c(
                                            "span",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                " Location provided by GPS "
                                              ),
                                            ]
                                          )
                                        : _vm.logger.location &&
                                          _vm.logger.location.source ==
                                            "CELLULAR"
                                        ? _c(
                                            "span",
                                            { staticClass: "text-muted" },
                                            [
                                              _vm._v(
                                                " Approximate position based on cell towers. Location powered by "
                                              ),
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href: "https://unwiredlabs.com/",
                                                  },
                                                },
                                                [_vm._v("Unwired Labs")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass: "text-center",
                                      staticStyle: {
                                        "background-color": "lightgrey",
                                        height: "100%",
                                      },
                                    },
                                    [_vm._v(" Location Currently unavailable ")]
                                  ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mt-3" },
                [
                  _c(
                    "b-col",
                    { staticClass: "mb-3", attrs: { lg: "8" } },
                    [
                      _c("file-table", {
                        attrs: { "type-filter": "PLAYBACK" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", lg: "4" } },
                    [
                      _c(
                        "b-card",
                        { staticClass: "mb-2" },
                        [
                          _c(
                            "template",
                            { slot: "header" },
                            [
                              _c(
                                "b-row",
                                {
                                  staticClass: "status-header",
                                  attrs: {
                                    "align-v": "center",
                                    "align-h": "between",
                                    "no-gutters": "",
                                  },
                                },
                                [
                                  _c("b-col", { attrs: { cols: "4" } }, [
                                    _c("span", [_vm._v("Status")]),
                                  ]),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "5" } },
                                    [
                                      _vm.logger.modemSignal != null
                                        ? _c(
                                            "modem-signal-icon",
                                            {
                                              staticClass: "float-right",
                                              attrs: {
                                                bars: _vm.logger.modemSignal,
                                                offline: _vm.dataTooOld(
                                                  _vm.logger.last_seen
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.logger.modemCarrier
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-table-simple",
                            [
                              _c(
                                "b-tbody",
                                [
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-td", [_vm._v("Status")]),
                                      _vm.logger.status
                                        ? _c("b-td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatStatus(
                                                  _vm.logger.status
                                                )
                                              )
                                            ),
                                          ])
                                        : _c("b-td", [_vm._v("unavailable")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-td", [_vm._v("Battery Voltage")]),
                                      _vm.logger.status
                                        ? _c("b-td", [
                                            _vm._v(
                                              _vm._s(_vm.logger.batteryVolts) +
                                                " V"
                                            ),
                                          ])
                                        : _c("b-td", [_vm._v("unavailable")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-td", [
                                        _vm._v("Ambient Temperature"),
                                      ]),
                                      _vm.logger.status
                                        ? _c("b-td", [
                                            _vm._v(
                                              _vm._s(_vm.logger.ambientTemp) +
                                                " °C"
                                            ),
                                          ])
                                        : _c("b-td", [_vm._v("unavailable")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-td", [_vm._v("Firmware")]),
                                      _vm.logger.status
                                        ? _c("b-td", [
                                            _vm._v(_vm._s(_vm.logger.firmware)),
                                          ])
                                        : _c("b-td", [_vm._v("unavailable")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-td", [_vm._v("Free Memory")]),
                                      _vm.logger.status
                                        ? _c("b-td", [
                                            _vm._v(
                                              _vm._s(_vm.logger.freeMemory) +
                                                " %"
                                            ),
                                          ])
                                        : _c("b-td", [_vm._v("unavailable")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _c(
                        "b-card",
                        {
                          staticClass: "mb-2",
                          attrs: { header: "Quota Usage" },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.formatFileSize(_vm.logger.quota.used)
                              ) +
                              " used of " +
                              _vm._s(
                                _vm.logger.quota.total !== null
                                  ? _vm.formatFileSize(_vm.logger.quota.total)
                                  : "unlimited"
                              ) +
                              " "
                          ),
                          _c("b-progress", {
                            staticClass: "mt-3",
                            attrs: {
                              value: _vm.logger.quota.used,
                              max: _vm.logger.quota.total || 0,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("b-card", { attrs: { header: "Subscription" } }, [
                        _vm._v(" Subscription due to expire on... "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              this.cmds.length > 0
                ? _c(
                    "b-toast",
                    {
                      attrs: {
                        id: "logger-command-processing",
                        toaster: "b-toaster-bottom-right",
                        variant: "accent",
                        visible: "",
                        "is-status": "",
                        "no-auto-hide": "",
                        "no-close-button": "",
                        solid: "",
                        "no-hover-pause": "",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "toast-title" },
                        [
                          _c("b-spinner", {
                            staticClass: "mr-3",
                            attrs: { small: "" },
                          }),
                          _vm._v(" Command in Progress "),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("b-col", { attrs: { cols: "auto", md: "4" } }, [
                            _c("b", [_vm._v("Command:")]),
                          ]),
                          _c("b-col", { attrs: { cols: "auto" } }, [
                            this.cmds[0].command === "start"
                              ? _c("span", [_vm._v("Start Recording")])
                              : this.cmds[0].command === "stop"
                              ? _c("span", [_vm._v("Stop Recording")])
                              : this.cmds[0].command === "restart"
                              ? _c("span", [_vm._v("Restart Recording")])
                              : this.cmds[0].command === "config_load"
                              ? _c("span", [
                                  _vm._v(
                                    " Activate " +
                                      _vm._s(this.cmds[0].params[0]) +
                                      " "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(this.cmds[0].command)),
                                ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("b-col", { attrs: { cols: "auto", md: "4" } }, [
                            _c("b", { staticClass: "mr-2" }, [
                              _vm._v("Status:"),
                            ]),
                          ]),
                          _c("b-col", { attrs: { cols: "auto" } }, [
                            !this.cmds[0].started
                              ? _c("span", [_vm._v("Waiting for logger...")])
                              : _c("span", [_vm._v("Running")]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("b-col", [
                            this.cmds.length > 1
                              ? _c("b", [
                                  _vm._v(
                                    " " +
                                      _vm._s(this.cmds.length - 1) +
                                      " more commands queued after this "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm.error
        ? _c("h3", [_vm._v(_vm._s(_vm.error) + " ")])
        : _vm._e(),
      !_vm.loading
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "logger-config-modal",
                title: "Settings",
                size: "xl",
                "hide-footer": "",
              },
              on: { shown: _vm.loggerSettingsShown },
            },
            [
              _c(
                "b-tabs",
                [
                  _c(
                    "b-tab",
                    { attrs: { title: "Logger Configuration" } },
                    [
                      _c(
                        "b-container",
                        { staticClass: "mt-2", attrs: { fluid: "" } },
                        [
                          _c("b-row"),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12", lg: "9" } },
                                [
                                  _c("file-table", {
                                    attrs: {
                                      title: "Configuration Files",
                                      "type-filter": "CONFIGURATION",
                                    },
                                    on: {
                                      "activate-config": _vm.activateConfig,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "12", lg: "3" } },
                                [
                                  _vm._v(" Upload new config file: "),
                                  _c("b-form-file", {
                                    attrs: { accept: ".plc" },
                                    model: {
                                      value: _vm.configFileUpload.file,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.configFileUpload,
                                          "file",
                                          $$v
                                        )
                                      },
                                      expression: "configFileUpload.file",
                                    },
                                  }),
                                  _c(
                                    "b-checkbox",
                                    {
                                      staticClass: "mt-1",
                                      attrs: { switch: "" },
                                      model: {
                                        value: _vm.configFileUpload.promptLoad,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.configFileUpload,
                                            "promptLoad",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "configFileUpload.promptLoad",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Activate config for start of next session "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-btn",
                                    {
                                      staticClass: "mt-1",
                                      attrs: {
                                        disabled:
                                          !_vm.configFileUpload.file ||
                                          _vm.configFileUpload.inProgress,
                                        block: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.uploadConfigFile()
                                        },
                                      },
                                    },
                                    [
                                      _vm.configFileUpload.inProgress
                                        ? [
                                            _c("b-spinner", {
                                              attrs: { small: "" },
                                            }),
                                            _vm._v(" Uploading... "),
                                          ]
                                        : _vm.configFileUpload.result === true
                                        ? [
                                            _c("fa-icon", {
                                              style: { color: "green" },
                                              attrs: { icon: "check" },
                                            }),
                                            _vm._v(" Upload "),
                                          ]
                                        : _vm.configFileUpload.result === false
                                        ? [
                                            _c("fa-icon", {
                                              style: { color: "red" },
                                              attrs: { icon: "times" },
                                            }),
                                            _vm._v(" Upload "),
                                          ]
                                        : [_vm._v(" Upload ")],
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Location" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12", lg: "6" } },
                            [
                              _c(
                                "b-row",
                                {
                                  staticClass: "mt-4",
                                  attrs: { "no-gutters": "" },
                                },
                                [
                                  _c(
                                    "b-checkbox",
                                    {
                                      attrs: { switch: "" },
                                      model: {
                                        value: _vm.settings.useManualLocation,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.settings,
                                            "useManualLocation",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "settings.useManualLocation",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " Manually override logger location "
                                      ),
                                    ]
                                  ),
                                  _c("p", { staticClass: "text-muted" }, [
                                    _vm._v(
                                      " Click the map and drag the icon to set the logger location. "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { cols: "12", lg: "6" } },
                            [
                              _vm.settings.useManualLocation
                                ? _c("map-location-picker", {
                                    ref: "locPick",
                                    attrs: {
                                      center:
                                        _vm.displayedLocation || undefined,
                                      markerLocation: _vm.logger.location
                                        ? _vm.logger.location.point
                                        : undefined,
                                      zoom: _vm.displayedLocation ? 7 : 2,
                                    },
                                    model: {
                                      value: _vm.settings.manualLocation,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.settings,
                                          "manualLocation",
                                          $$v
                                        )
                                      },
                                      expression: "settings.manualLocation",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        {
                          staticClass: "mt-2 mr-2",
                          attrs: { "align-h": "end" },
                        },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "b-btn",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.setLocationOverride()
                                    },
                                  },
                                },
                                [_vm._v("Save")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-tab",
                    { attrs: { title: "Users" } },
                    [_c("logger-page-users", { attrs: { lid: this.lid } })],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }