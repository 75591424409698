var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "l-map",
    {
      ref: "map",
      staticStyle: { height: "300px", width: "300px" },
      attrs: { zoom: _vm.zoomData, center: _vm.centerData },
      on: {
        "update:zoom": _vm.zoomUpdated,
        "update:center": _vm.centerUpdated,
        click: _vm.mapClicked,
      },
    },
    [
      _c("l-tile-layer", { attrs: { url: _vm.url } }),
      _c(
        "l-marker",
        {
          attrs: {
            visible: !!_vm.markerLocation,
            "lat-lng": _vm.markerLocation,
          },
        },
        [_c("l-tooltip", [_vm._v("Logger reported location")])],
        1
      ),
      _c("l-marker", {
        ref: "userMarker",
        attrs: {
          visible: _vm.visible,
          draggable: true,
          "lat-lng": _vm.value || _vm.centerData,
        },
        on: { "update:latLng": _vm.markerDragged },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }